import React from "react";

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 * Logo comes with a property vallue called `fill`. `fill` is useful
 * when you want to change your logo depending on the theme you are on.
 */
export default function Logo({ fill }) {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="180.918728px"
      height="50px"
      viewBox="0 0 1000.000000 245.000000"
    >
      <g
        transform="translate(0.000000,245.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M385 2423 c-149 -52 -265 -153 -330 -287 -48 -100 -58 -156 -53 -309
3 -104 9 -145 26 -191 51 -138 154 -261 265 -316 154 -75 378 -80 540 -11 l47
20 0 166 c0 91 -3 165 -7 165 -5 0 -28 -14 -53 -30 -180 -120 -372 -80 -452
95 -32 71 -32 189 1 262 80 179 303 226 453 94 20 -17 41 -31 47 -31 8 0 11
52 11 174 l0 174 -52 23 c-47 21 -71 24 -208 27 -147 2 -159 1 -235 -25z"
        />
        <path
          d="M3261 2435 c-146 -41 -285 -151 -353 -280 -51 -98 -71 -181 -71 -300
0 -181 56 -321 177 -440 120 -119 242 -161 441 -152 110 5 198 27 252 62 22
15 23 19 23 175 0 107 -4 160 -11 160 -6 0 -31 -14 -56 -31 -165 -114 -364
-81 -441 74 -88 177 -10 387 164 442 92 29 195 5 284 -65 21 -16 41 -30 44
-30 3 0 6 79 6 175 l0 176 -47 21 c-42 19 -69 22 -203 25 -114 2 -169 -1 -209
-12z"
        />
        <path
          d="M6631 2430 c-29 -11 -72 -34 -96 -52 -136 -104 -176 -318 -85 -462
51 -80 88 -99 319 -166 90 -26 121 -54 121 -107 0 -45 -20 -77 -60 -98 -64
-33 -160 -9 -272 66 -33 23 -64 37 -68 33 -4 -5 -32 -60 -61 -123 l-53 -114
29 -23 c48 -38 164 -92 235 -108 81 -19 218 -20 286 -2 119 32 212 108 262
216 20 43 27 75 30 146 5 118 -13 184 -69 248 -52 58 -107 87 -244 125 -117
33 -150 50 -167 87 -26 57 45 112 129 100 47 -6 110 -35 160 -72 17 -13 35
-24 40 -24 8 1 113 218 113 236 0 14 -125 79 -189 98 -81 24 -289 21 -360 -4z"
        />
        <path
          d="M8051 2434 c-93 -25 -185 -81 -253 -153 -105 -111 -158 -254 -158
-425 1 -188 50 -318 164 -432 114 -114 245 -164 431 -164 357 0 557 198 573
568 l5 122 -292 0 -292 0 3 -132 3 -133 118 0 c71 0 117 -4 117 -10 0 -5 -10
-28 -23 -50 -16 -29 -38 -48 -77 -68 -49 -25 -63 -28 -125 -24 -119 7 -193 61
-234 172 -30 79 -29 222 0 302 46 121 160 189 279 165 59 -13 113 -50 145
-100 11 -17 25 -32 30 -32 18 -1 295 133 295 142 0 18 -58 94 -107 140 -56 52
-121 88 -202 112 -72 21 -322 21 -400 0z"
        />
        <path d="M930 1860 l0 -570 170 0 170 0 0 570 0 570 -170 0 -170 0 0 -570z" />
        <path
          d="M1290 2420 c0 -9 353 -872 442 -1082 l20 -48 117 0 116 1 223 548
c123 302 226 558 229 570 5 21 4 21 -165 21 l-170 0 -14 -37 c-8 -21 -59 -161
-114 -310 l-99 -273 -121 308 -122 307 -171 3 c-117 2 -171 -1 -171 -8z"
        />
        <path d="M2460 1860 l0 -570 170 0 170 0 0 570 0 570 -170 0 -170 0 0 -570z" />
        <path
          d="M4600 1861 l0 -571 273 0 c304 0 364 8 468 61 308 155 378 664 127
925 -32 33 -82 73 -111 87 -107 54 -149 60 -464 64 l-293 5 0 -571z m487 276
c116 -30 173 -106 181 -238 8 -139 -35 -243 -124 -295 -37 -22 -63 -28 -125
-32 l-79 -5 0 292 0 291 48 0 c26 0 71 -6 99 -13z"
        />
        <path
          d="M5650 1860 l0 -570 345 0 345 0 0 140 0 140 -185 0 -185 0 0 75 0 75
155 0 156 0 -3 143 -3 142 -153 0 -152 0 0 73 0 72 180 0 180 0 0 140 0 140
-340 0 -340 0 0 -570z"
        />
        <path d="M7260 1860 l0 -570 170 0 170 0 0 570 0 570 -170 0 -170 0 0 -570z" />
        <path
          d="M8860 1860 l0 -570 171 0 170 0 -7 323 -6 322 242 -322 242 -323 164
0 164 0 0 570 0 570 -166 0 -165 0 7 -317 6 -318 -51 65 c-28 36 -137 178
-243 317 l-193 252 -167 0 -168 1 0 -570z"
        />
        <path
          d="M38 1135 c-5 -5 -8 -262 -8 -572 l0 -563 293 0 c253 0 301 2 358 18
228 63 369 274 369 552 0 179 -48 308 -157 418 -69 70 -134 105 -240 128 -75
16 -602 32 -615 19z m539 -313 c85 -44 128 -129 126 -252 -1 -84 -16 -137 -56
-196 -43 -63 -100 -92 -200 -100 l-77 -7 0 293 0 293 83 -5 c57 -3 94 -11 124
-26z"
        />
        <path
          d="M1354 1132 c-6 -4 -95 -239 -199 -522 -103 -283 -196 -536 -206 -562
l-19 -48 174 0 174 0 32 95 32 95 183 0 183 0 31 -86 c17 -47 31 -89 31 -95 0
-5 70 -9 171 -9 158 0 171 1 167 17 -14 49 -402 1100 -410 1111 -12 13 -324
18 -344 4z m266 -674 c0 -5 -43 -8 -95 -8 -52 0 -95 3 -95 7 0 3 21 79 47 169
l47 163 48 -162 c26 -89 48 -165 48 -169z"
        />
        <path
          d="M1963 1133 c-10 -3 -13 -41 -13 -139 l0 -134 120 0 120 0 0 -430 0
-430 170 0 170 0 0 430 0 430 115 0 116 0 -3 138 -3 137 -390 2 c-214 1 -396
-1 -402 -4z"
        />
        <path
          d="M3016 1132 c-4 -8 -356 -964 -396 -1079 l-19 -53 172 0 173 0 30 93
30 92 186 3 186 2 32 -95 32 -95 170 0 c132 0 169 3 166 13 -81 227 -404 1106
-411 1115 -11 14 -342 18 -351 4z m224 -507 c23 -82 45 -156 47 -162 4 -10
-18 -13 -91 -13 -68 0 -96 4 -96 12 0 7 11 48 24 92 14 45 34 114 46 154 12
41 23 72 25 70 1 -1 22 -70 45 -153z"
        />
        <path
          d="M4607 1134 c-4 -4 -7 -261 -7 -571 l0 -563 345 0 345 0 0 135 0 135
-175 0 -175 0 -2 433 -3 432 -161 3 c-88 1 -163 -1 -167 -4z"
        />
        <path
          d="M5725 1131 c-3 -5 -95 -254 -204 -553 -110 -299 -202 -551 -206 -560
-7 -17 5 -18 167 -18 l174 0 30 93 30 92 186 3 186 2 32 -95 32 -95 169 0 c93
0 169 2 169 4 0 7 -402 1101 -411 1118 -9 16 -25 18 -179 18 -93 0 -171 -4
-175 -9z m275 -678 c0 -2 -44 -3 -98 -3 l-99 0 44 153 c24 83 46 159 49 167 4
10 100 -284 104 -317z"
        />
        <path
          d="M6518 1134 c-5 -4 -8 -261 -8 -571 l0 -564 318 3 c306 3 319 4 376
27 69 28 129 78 159 135 19 35 22 57 22 146 0 98 -2 109 -29 158 -31 55 -83
104 -131 122 l-28 10 38 37 c56 55 79 113 79 203 0 136 -67 231 -191 271 -61
19 -97 22 -333 26 -146 3 -268 2 -272 -3z m431 -255 c34 -12 61 -53 61 -92 0
-54 -65 -97 -147 -97 l-33 0 0 100 0 100 44 0 c23 0 57 -5 75 -11z m68 -455
c36 -27 48 -67 34 -114 -15 -51 -56 -70 -150 -70 l-71 0 0 106 0 107 80 -5
c60 -4 88 -10 107 -24z"
        />
      </g>
    </svg>
  );
}
